/* * {
  border: 1px solid red;
} */

:root {
  --naranja: #053485;
  --naranja-oscuro: #1b4185;
  --naranja-oscuro-rgb: rgb(12,34,71);
  --gradiente-uno: linear-gradient(
    90deg,
    #0c2247 11%,
    #053485 88%
  );
  --gradiente-aux: linear-gradient(to right, #0c2247, #053485);
  --naranja-suave: #044ac4;
  --gris: #dad2ca;
  --gris-dos: #545454;
  --gris-tres: #707170;
  --gris-cuatro: #8c8c8c;
  --cafe: #786c58;
  --negro: #000;
  --blanco: #fff;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* General */
.background-gray {
  background: var(--gris);
}

.text-color-orange {
  color: var(--naranja-oscuro);
}

.background-gray-color {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(235, 231, 228, 1) 100%
  );
}

.button-transparent {
  background: transparent;
}

hr {
  border: 1px solid var(--naranja-suave) !important;
}

.hr-2 {
  margin: 0 !important;
}

.font-gray {
  color: var(--gris-cuatro);
}

.iconoSearch {
  position: absolute;
  float: right;
  top: 6px;
  right: 31px;
  color: var(--naranja-suave);
  font-size: 1.5rem;
}

.orange-button {
  background-color: var(--naranja);
  color: var(--blanco);
  width: 100%;
  border: 0;
  padding: 0.5rem;
  border-radius: 1rem;
}

.orange-dark-button {
  background-color: var(--naranja-oscuro);
  color: var(--blanco);
  width: 100%;
  border: 0;
  padding: 0.5rem;
  border-radius: 1rem;
}

.red-button{
  background-color: red !important;
}

.only-orange-button {
  background-color: var(--naranja-oscuro) !important;
  color: var(--blanco) !important;
}

.only-orange-button:hover {
  background-color: var(--naranja) !important;
}

.orange-dark-button:hover {
  background-color: var(--naranja);
}

.orange-icon {
  color: var(--naranja-oscuro);
}

.orange-icon:hover {
  color: var(--naranja);
}

.person-icon {
  fill: var(--naranja-oscuro);
  width: 150px;
  height: 150px;
}

.row-table:hover .rs-table-cell {
  background-color: var(--gris) !important;
  color: var(--cafe);
}

.white-icon {
  color: var(--cafe);
  transition: all ease 0.5s;
}

.white-icon:hover {
  color: var(--naranja-oscuro);
  transition: all ease 0.1s;
}

/* Tamaños y Tipos de letra */
.mouse-pointer {
  cursor: pointer;
}

.font-16 {
  font-size: 16px;
}

.font-24 {
  font-size: 24px;
}

.font-32 {
  font-size: 32px;
}

/* Imprimir */
.print-source {
  display: none;
}

@media print {
  .print-source {
    display: block;
  }

  .print-source .print-header {
    margin-left: 50%;
  }

  .print-source .print-info {
    margin-left: 10%;
    font-size: 16px;
  }

  /* .table th {
    background: var(--naranja-oscuro);
    color: var(--blanco);
    padding: 1rem;
  } */
  /* Estado Cuenta */
  .print-source div div .table thead tr th {
    background-color: #fc6404;
    color: #000;
    padding: 1rem;
  }

  /* Recibo */
  .print-source .print-recibo-header {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    margin-bottom: 4rem;
  }

  .print-source .print-recibo-header img {
    width: 200px;
  }

  .print-source .print-recibo-detail {
    font-size: 24px;
    text-align: center;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
  }

  .print-source .print-recibo-detail table thead tr td {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .print-source .print-recibo-footer {
    font-size: 24px;
    margin-bottom: 8rem;
  }

  .print-source .print-recibo-firma {
    font-size: 24px;
    padding-left: 8rem;
    padding-right: 8rem;
    display: flex;
    justify-content: space-between;
  }
}
/* Login */
.loginLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  opacity: 0.5;
  cursor: wait;
  z-index: 9999;
}

.loagin_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: darkgray;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.fondo {
  background: var(--naranja-oscuro-rgb);
  background: var(--gradiente-aux);

  width: 100%;
  height: 100vh;
}

.form-container {
  width: 650px;
  padding: 2rem;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 32px;
  background: var(--blanco);
  box-shadow: 10px 12px 64px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 12px 64px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 12px 64px -6px rgba(0, 0, 0, 0.75);
}

.imgLogo {
  width: 100%;
  height: 100px;
}

.recoverPassword {
  text-decoration: none;
  color: var(--gris-cuatro);
}
/* Menu */
.logoMenu {
  width: 100px;
  height: auto;
}

/* Large Menu */
.largeMenu {
  height: 60px;
  background: var(--naranja-oscuro-rgb);
  background: var(--gradiente-aux);
}

.largeMenu-option {
  background: var(--blanco);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-left: 36px;
  padding-right: 36px;
}

/* Tablas */
.container_head_sticky {
  overflow: auto;
  max-height: 50vh;
  z-index: 999;
}

.header_sticky {
  position: sticky !important;
  top: 0 !important;
}

.tabla-home {
  border: 0.5px solid var(--gris);
  text-align: center;
  width: 100%;
  border-radius: 25px;
}

.table-pagos {
  border-collapse: inherit;
  border-spacing: 0;
  text-align: center;
  width: 100%;
}

.table th {
  background: var(--naranja-oscuro);
  color: var(--blanco);
  padding: 1rem;
}

.table-search {
  border: 2px solid transparent !important;
  border-collapse: separate !important;
  border-radius: 7px;
  border-spacing: 0px;
  width: 100%;
}

.table-search th,
td {
  padding: 9px 10px !important;
}

.table-search th {
  background-color: white;
  color: var(--negro);
}

.table-search tbody tr:first-child td.topLeft {
  border: 1px solid gray;
  border-top-left-radius: 10px !important;
  border-right: none;
}

.table-search tbody tr:first-child td.topRight {
  border: 1px solid gray;
  border-top-right-radius: 10px !important;
  border-left: none;
}

.table-search tbody tr:last-child td.topLeft {
  border: 1px solid gray;
  border-bottom-left-radius: 10px !important;
  border-right: none;
}

.table-search tbody tr:last-child td.topRight {
  border: 1px solid gray;
  border-bottom-right-radius: 10px !important;
  border-left: none;
}

.table-search tbody tr td.enmedio {
  border: 1px solid gray;
  border-left: none;
  border-right: none;
}

.table-search tbody tr td.cornerLeft {
  border: 1px solid gray;
  border-right: none;
}

.table-search tbody tr td.cornerRight {
  border: 1px solid gray;
  border-left: none;
}

/*Tasas Detalle*/
.detalle-tasa {
  border: 1px solid var(--naranja-suave);
  border-radius: 5px;
  margin-top: 6px;
  padding: 1rem;
}

/* Media Querys */

/* Mediano */
@media (max-width: 768px) {
  .imgLogo {
    width: 300px;
  }
}

/* Pequeño */
@media (max-width: 576px) {
  .imgLogo {
    width: 200px;
  }
}

/* Loader */
.loader {
  z-index: 1000;
  width: 48px;
  height: 48px;
  border: 3px solid var(--negro);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  z-index: 1000;

  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: var(--naranja-oscuro);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
